import React from "react";
import "../App.css";
const Header = ({ title }) => {
  return (
    <div className="main-header">
      <div>{title}</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>Контакты (Дмитрий): </div>
        <div style={{ marginLeft: 5 }}>тел.</div>
        <a href="tel:+79-15-841-63-44" style={{ color: "#749DD9" }}>
          89158416344
        </a>
        <div style={{ marginLeft: 5 }}>Почта</div>
        <a href="mailto:Vopilovskiydmitr@mail.ru" style={{ color: "#749DD9" }}>
          Vopilovskiydmitr@mail.ru
        </a>
      </div>
    </div>
  );
};

export default Header;
