import "./App.css";
import Header from "./components/Header";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original:
      "https://37.img.avito.st/image/1/1.iAKGXbayJOuw9ObumhuyGVT-JO0m_CY.S98D8pC88pN-7USW031BD6ntPNj0rwDAn5bilxVOwZU",
    thumbnail:
      "https://37.img.avito.st/image/1/1.iAKGXbayJOuw9ObumhuyGVT-JO0m_CY.S98D8pC88pN-7USW031BD6ntPNj0rwDAn5bilxVOwZU",
  },
  {
    original:
      "https://15.img.avito.st/image/1/1.4tQM77ayTj06Row4RKjYz95MTjusTkw.tV15gDAcGaKfRiPwC55MeGzl5DEGGEp0awrmbwWH_bw",
    thumbnail:
      "https://15.img.avito.st/image/1/1.4tQM77ayTj06Row4RKjYz95MTjusTkw.tV15gDAcGaKfRiPwC55MeGzl5DEGGEp0awrmbwWH_bw",
  },
  {
    original:
      "https://90.img.avito.st/image/1/1.p9bVJbayCz_jjMk6lze1wweGCzl1hAk.9AgkQBovi9Xxt2F9QljYFM3zWXgLMumZ2d06XxxBUSo",
    thumbnail:
      "https://90.img.avito.st/image/1/1.p9bVJbayCz_jjMk6lze1wweGCzl1hAk.9AgkQBovi9Xxt2F9QljYFM3zWXgLMumZ2d06XxxBUSo",
  },
  {
    original:
      "https://49.img.avito.st/image/1/1.vHi5TbayEJGP5NKUzQuGY2vuEJcZ7BI.F9sYx8T8JDSHhvIiV3te_shtRGH0q9bpdmPUajeUpig",
    thumbnail:
      "https://49.img.avito.st/image/1/1.vHi5TbayEJGP5NKUzQuGY2vuEJcZ7BI.F9sYx8T8JDSHhvIiV3te_shtRGH0q9bpdmPUajeUpig",
  },
  {
    original:
      "https://02.img.avito.st/image/1/1.y5QCW7ayZ3008qV4GBDBj9D4Z3ui-mU.3eUtt9w7lnWqwdTRw6MGBKzlzTyPb5gXgAGIe1vFKA0",
    thumbnail:
      "https://02.img.avito.st/image/1/1.y5QCW7ayZ3008qV4GBDBj9D4Z3ui-mU.3eUtt9w7lnWqwdTRw6MGBKzlzTyPb5gXgAGIe1vFKA0",
  },
  {
    original:
      "https://62.img.avito.st/image/1/1.s5TCO7ayH330kt14qH2JjxCYH3timh0.0aqoDTRCYhZSPU7W6lCNZsO3ciQ5XbLp-x4R3roBnJo",
    thumbnail:
      "https://62.img.avito.st/image/1/1.s5TCO7ayH330kt14qH2JjxCYH3timh0.0aqoDTRCYhZSPU7W6lCNZsO3ciQ5XbLp-x4R3roBnJo",
  },
  {
    original:
      "https://81.img.avito.st/image/1/1.XORYMLay8A1umTIIbHVm_4qT8Av4kfI._an5JsIMuA9LdhAb7oGi2ngvQixjPsh492vNpksUpyI",
    thumbnail:
      "https://81.img.avito.st/image/1/1.XORYMLay8A1umTIIbHVm_4qT8Av4kfI._an5JsIMuA9LdhAb7oGi2ngvQixjPsh492vNpksUpyI",
  },
  {
    original:
      "https://78.img.avito.st/image/1/1.s4hgm7ayH2FWMt1kComhnbI4H2fAOh0.OKnNE_IaAgJGKJpgFsqs5jS4k1pMWmydoYCApimsn_A",
    thumbnail:
      "https://78.img.avito.st/image/1/1.s4hgm7ayH2FWMt1kComhnbI4H2fAOh0.OKnNE_IaAgJGKJpgFsqs5jS4k1pMWmydoYCApimsn_A",
  },
  {
    original:
      "https://62.img.avito.st/image/1/1.g9PVdbayLzrj3O0_32eRxgfWLzx11C0.ZI38g0lsjavz-iSiLhwKX_5JvupEtKgHGQTb4eTfCpk",
    thumbnail:
      "https://62.img.avito.st/image/1/1.g9PVdbayLzrj3O0_32eRxgfWLzx11C0.ZI38g0lsjavz-iSiLhwKX_5JvupEtKgHGQTb4eTfCpk",
  },
  {
    original:
      "https://92.img.avito.st/image/1/1.obNXLLayDVphhc9fGT6zpoWPDVz3jQ8.WttjgAIZyBajWSdAMepIO3ZiF2S14N8xLUmTpEEb8Us",
    thumbnail:
      "https://92.img.avito.st/image/1/1.obNXLLayDVphhc9fGT6zpoWPDVz3jQ8.WttjgAIZyBajWSdAMepIO3ZiF2S14N8xLUmTpEEb8Us",
  },
  {
    original:
      "https://07.img.avito.st/image/1/1.VvUHYLay-hwxyTgZJ3FE4NXD-hqnwfg.JDShisU6KqVrYMdRgXgxb3KBvsi18il5ua3JEsIszs4",
    thumbnail:
      "https://07.img.avito.st/image/1/1.VvUHYLay-hwxyTgZJ3FE4NXD-hqnwfg.JDShisU6KqVrYMdRgXgxb3KBvsi18il5ua3JEsIszs4",
  },
  {
    original:
      "https://10.img.avito.st/image/1/1.Uz2OwLay_9S4aT3RpNFBKFxj_9IuYf0.XgPOmelHmWqehdof3jvDHJ_OT3SMR1RbYNyNYBuoRUw",
    thumbnail:
      "https://10.img.avito.st/image/1/1.Uz2OwLay_9S4aT3RpNFBKFxj_9IuYf0.XgPOmelHmWqehdof3jvDHJ_OT3SMR1RbYNyNYBuoRUw",
  },
];

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header title="Снять квартиру в Крыму" />
      </header>
      <ImageGallery items={images} />
      <div className="info-container">
        <div className="address">
          <div>
            <h3>Адрес</h3>
            <div>
              Республика Крым, Черноморский р-н, Черноморское сельское
              поселение, пос. городского типа Черноморское, жилой комплекс
              Итальянская Деревня
            </div>
          </div>
          <h3>О доме</h3>
          <div>
            4 этажа <br />
            Парковка на улице, бесплатна для гостей
          </div>
          <div>
            Количество комнат: студия
            <br />
            Общая площадь: 21 м²
            <br />
            Этаж: 3 из 4 <br /> Балкон или лоджия: балкон Техника: кондиционер,
            холодильник, плита, стиральная машина, водонагреватель, телевизор,
            фен, утюг Интернет и ТВ: Wi-Fi, телевидение
            <br />
            Комфорт: постельное белье, полотенца, средства гигиены
            <br />
            Возможна помесячная аренда: Нет
          </div>
        </div>
        <div className="description">
          <div>
            <h3>Описание</h3>
            <div>
              Описание Апартамент расположен в комплексе «Сорренто»,Новый
              комплекс, в 50 м. от моря ,на третем этаже с видом на море,
              площадь апартамента 21м2. есть лифт. Количество спальных мест 4
              (два дивана).Минимуим трое суток. В апартаменте есть все
              необходимое для комфортного проживания.
              <br />
              Wi fi ✅
              <br />
              Телевизор ✅
              <br />
              Кондиционер ✅
              <br />
              Плита✅
              <br />
              Холодильник✅
              <br />
              Утюг✅
              <br />
              Посуда ✅
              <br />
              Эл. Чайник✅
              <br />
              Постельное белье✅
              <br />
              Полотенца✅
            </div>
            <div>
              <h3>Правила</h3>
              Количество гостей: 4 <br />
              Можно с детьми: да
              <br />
              Можно с животными: да
              <br />
              Можно курить: нет
              <br />
              Разрешены вечеринки: нет
              <br />
              Есть отчётные документы: да
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
